import React from "react";

const ReputedCounter = () => {
  return (
    <main id="main">
      <section className="breadcrumbs">
        <div className="container">
          <h2 style={{ textAlign: "center" }}>Reputed Counters</h2>
        </div>
      </section>

      <section className="inner-page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th style={{width: "30%"}}>License No.</th>
                    <th>Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1064</td>
                    <td>UPPER NONGTHYMMAI, SHILLONG</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ReputedCounter;
